import './SuceesPages.css';
import React, {useState} from 'react';
import {Helmet} from "react-helmet";
import TagManager from 'react-gtm-module';
import queryString from 'query-string';
import {useNavigate, useLocation} from "react-router-dom";
import axios from 'axios';
import info from '../appConfig.json';
import data from '../data.json';

function Success2(props) {
    var databody = "";
    let amenities = "";
    var backgroundImage = "";

    const tagManagerArgs = {
        gtmId: info.GTMID
    }

    const navigate = useNavigate();
    const location = useLocation();
    const [double, setDouble] = useState(isConnect());
    const [connectMsg, setConnectMsg] = useState(isConnect());
    const [mistURL, setMistURL] = useState("");
    const [btnConnect, setBtnConnect] = useState(false);
    const values = queryString.parse(location.search)
    var userLang = localStorage.getItem("language");
    var datalanguage = data[userLang];
    var urlPath = location.pathname;
    var username = "";
    var databodyLogin = "";
    var lambdaFunctionName = "";
    var imgUrl = "https://www.bp.com/content/dam/bp/country-sites/nl-nl/netherlands/home/bp-nederland/bp-fleet/headers/fleet-header-bpme.jpg.img.500.medium.jpg";

    const [scrollState, setScrollState] = useState("");

    redirecttoBPSFPage();
    authCallback();
    checkAppState();
    //isConnect();
    handleScroll();

    function redirecttoBPSFPage() {
        // if (localStorage.getItem("isConnect") === "true" && localStorage.getItem("device") === "frn") {
        //   if (localStorage.getItem("saRedirection") === "za") {
        //     window.location.href = "https://www.bp.com/en_za/south-africa/home.html";
        //   }
        // }
    }

    function checkAppState() {
        // if (localStorage.getItem("con") != "") {
        //   window.location.href = info.appBaseUrl + getLandingPageLaguages(localStorage.getItem("language")) + "/" + localStorage.getItem("device") + "/LandingPage";
        // }
    }

    var loadPromotions = async () => {
        if (localStorage.getItem("isConnect") === "true")
            setBtnConnect(false);
        else {
            setBtnConnect(true);
        }

        // if (localStorage.getItem("device") === "frn") {
        //   if (localStorage.getItem("isConnect") !== "true") {
        //     localStorage.setItem("isConnect", true);
        //     var response1 = await userLoginProcess();

        //     document.getElementById("username").value = "guest" + info.userIdDomainExt;
        //     document.getElementById("btn").click();
        //     localStorage.setItem("con", new Date());
        //   }
        // }
        // else if (localStorage.getItem("device") === "synapse") {
        //   if (localStorage.getItem("isConnect") === "true")
        //     setBtnConnect(false);
        //   else {
        //     setBtnConnect(true);
        //   }
        // }

        // else if (localStorage.getItem("device") === "synapse") {
        //   if (localStorage.getItem("isConnect") !== "true") {
        //     var response1 = await userLoginProcess();
        //     localStorage.setItem("isConnect", true);
        //     if (response1 != "error") {
        //       localStorage.setItem("con", new Date());
        //       //window.open(response1);
        //       setMistURL(response1);
        //       setTimeout(() => {
        //         window.location.href = info.redirecturi;
        //       }, 2000);
        //     }
        //     console.log(response1);
        //   }
        // }
    }

    console.log("query values decode -", decodeURIComponent(values.code));

    function Hide() {
        // if (localStorage.getItem("isConnect") === "false") {
        //   setDouble(false);
        // }
        // else
        //   setDouble(true);
    }

    if (localStorage.getItem("device") === "frn") {
        databodyLogin = {
            "functionName": "frnLogin",
            "tokenurl": info.baseurl + info.tokenservice,
            "clientid": info.clientid,
            "clientSecret": info.clientSecret,
            //"redirecturi": info.redirecturi + "?state=frn",
            "redirecturi": info.redirecturi,
            "code": decodeURIComponent(values.code),
            "device": "Fortinet",
            "env": info.environment,
            "userIdDomainExt": info.userIdDomainExt,
            "urlpath": localStorage.getItem("urlpath"),
        }
    } else if (localStorage.getItem("device") === "synapse") {
        var expires = Math.round(new Date().getTime()) + 240;
        databodyLogin =
            {
                "functionName": "juniperLogin",
                "tokenurl": info.baseurl + info.tokenservice,
                "clientid": info.clientid,
                "clientSecret": info.clientSecret,
                //"redirecturi": info.redirecturi + "?state=synapse",
                "redirecturi": info.redirecturi,
                "code": decodeURIComponent(values.code),
                "device": "Juniper",
                "env": info.environment,
                "countrycode": localStorage.getItem("Countrycode"),
                "userIdDomainExt": info.userIdDomainExt,
                "urlpath": localStorage.getItem("urlpath"),
                "payload": {
                    "ap_mac": localStorage.getItem("ap_mac"),
                    "wlan_id": localStorage.getItem("wlan_id"),
                    "client_mac": localStorage.getItem("client_mac"),
                    "authorize_min": 1440,
                    "expires": parseInt(expires),
                    "download_kbps": 0,
                    "upload_kbps": 0,
                    "quota_mbytes": 0,
                    //below is the url which is redirected back from Mist portal
                    //"forward": info.appBaseUrl + localStorage.getItem("Countrycode") + "/synapse/Success",
                    //"forward": info.appBaseUrl + localStorage.getItem("Countrycode") + "/synapse/auth/callback",
                    //"forward": info.redirecturi + "?state=synapse",
                    "forward": info.redirecturi,
                    // "email": email,
                    "authorize_only": false
                }
            }
        console.log("forwardurl", info.redirecturi + localStorage.getItem("Countrycode") + "/synapse/Success");
    }

    function authCallback() {
        // if (localStorage.getItem("saRedirection") === "za") {
        //   window.location.href = "https://www.bp.com/en_za/south-africa/home.html";
        // }
        if (localStorage.getItem("isConnect") === null) {
            window.location.href = info.appBaseUrl + getLandingPageLaguages(localStorage.getItem("language")) + "/" + localStorage.getItem("device") + "/LandingPage";
        } else if (urlPath.includes("auth/callback")) {
            setTimeout(() => {
                localStorage.setItem("isConnect", "linkExpired");
                window.location.href = info.appBaseUrl + getLandingPageLaguages(localStorage.getItem("language")) + "/" + localStorage.getItem("device") + "/LandingPage";
            }, info.linkExpiryInMin * 60 * 1000);
        }
    }

    function handleScroll() {
        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
        const body = document.body;
        const html = document.documentElement;
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
        const windowBottom = windowHeight + window.pageYOffset;
        // if (windowBottom >= docHeight) {

        // } else {

        // }

        // setDouble(false);
        // if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        //   if (localStorage.getItem("isConnect") === "true") {
        //     setDouble(false);
        //   }
        //   else
        //     setDouble(true);
        // }
    }

    // window.onscroll = function (ev) {
    //   setDouble(false);
    //   if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
    //     setDouble(true);
    //   }
    // };

    // setInterval(() => {
    //   setDouble(false);
    //   if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
    //     if (localStorage.getItem("isConnect") === "true") {
    //       setDouble(false);
    //     }
    //     else
    //       setDouble(true);
    //   }
    // }, 3000)

    setTimeout(() => {
        callSnow();
        loadPromotions();
    }, 50);

    var userLoginProcess = async () => {
        var returnresult = "";
        await axios.post(info.lambdaUrl + lambdaFunctionName, databodyLogin
        ).then(resp => {
            returnresult = resp.data.body;
            console.log(resp);
        }).catch(err => console.log(err));
        return returnresult;
    }

    //store code from url to local storage
    localStorage.setItem("authcode", values.code);
    var handleConnect = async () => {
        //set time out for completing login process
        // if (localStorage.getItem("saRedirection") === "za") {
        //   window.location.href = "https://www.bp.com/en_za/south-africa/home.html";
        //   return;
        // }

        if (localStorage.getItem("device") === "synapse") {
            if (localStorage.getItem("isConnect") !== "true") {
                var response1 = await userLoginProcess();
                localStorage.setItem("isConnect", true);
                if (response1 != "error") {
                    localStorage.setItem("con", new Date());
                    //window.open(response1);
                    //setMistURL(response1);
                    //window.open(response1);
                    // setTimeout(() => {
                    //   window.location.href = info.redirecturi;
                    // }, 1000);
                    window.location.href = response1;
                }
                console.log(response1);
            }
        } else if (localStorage.getItem("device") === "frn") {
            if (localStorage.getItem("isConnect") !== "true") {
                localStorage.setItem("isConnect", true);
                var response1 = await userLoginProcess();

                document.getElementById("username").value = "guest" + info.userIdDomainExt;
                document.getElementById("btn").click();
                localStorage.setItem("con", new Date());
            }
        }


        // if (localStorage.getItem("device") === "synapse") {
        //   var response1 = await userLoginProcess();
        //   if (response1) {
        //     localStorage.setItem("isConnect", true);
        //     databodyLogin.functionName = "idpLogin";
        //     response1 = await userLoginProcess();
        //     if (response1 != "error") {
        //       localStorage.setItem("con", new Date());
        //       window.location.href = response1;
        //     }
        //   }
        //   console.log(response1);
        // }
        // if (localStorage.getItem("device") === "frn") {
        //   var response1 = await userLoginProcess();
        //   console.log(response1);
        //   if (response1 != "error") {
        //     localStorage.setItem("isConnect", true);
        //     document.getElementById("username").value = response1 + info.userIdDomainExt;
        //     document.getElementById("btn").click();
        //     localStorage.setItem("con", new Date());
        //   }
        // }
    }

    function isConnect() {
        var isCnt = false;
        if (localStorage.getItem("isConnect") === "true") {
            isCnt = false;
        } else
            isCnt = true;
        return isCnt;
    }

    function getLandingPageLaguages(lang) {
        let language = "";
        switch (lang) {
            case "en":
                language = "en_gb";
                break;
            case "nl":
                language = "nl_nl";
                break;
            case "de":
                language = "de_de";
                break;
            case "fr":
                language = "fr_lu";
                break;
            case "de":
                language = "de_lu";
                break;
            case "en":
                language = "en_us";
                break;
            default:
                language = "en_gb";
        }
        return language;
    }

    console.log(values.code);
    var id = "";
    var site_Id = "";

    if (localStorage.getItem("device") === "synapse") {
        site_Id = localStorage.getItem("ap_name");
        //site_Id = site_Id ? site_Id.substring(0, 10) : "";
        site_Id = getAPName(site_Id);
    } else if (localStorage.getItem("device") === "frn") {
        site_Id = localStorage.getItem("apname");
        //site_Id = site_Id ? site_Id.substring(0, 10) : "";
        site_Id = getAPName(site_Id);
    }

    function getAPName(site_Id) {
        var apName = "";
        if (site_Id) {
            apName = site_Id.split('-')[0];
        }
        return apName;
    }

    console.log(site_Id);

    databody = {
        "functionName": "Success",
        "siteId": site_Id,
        "env": info.environment,
        "urlpath": localStorage.getItem("urlpath"),
    }

    var callSnow = async () => {
        if (site_Id) {

            var response = await axios.post(info.lambdaUrl, databody
            ).then(resp => {
                console.log(resp);
                amenities = resp.data.body;

                if (localStorage.getItem("country") != "Netherland" && localStorage.getItem("country") != "UK" && localStorage.getItem("country") != "SouthAfrica") {
                    amenities = "";
                }

                TagManager.initialize(tagManagerArgs)
                window.dataLayer.push({
                    event: 'LoadJS',
                    site_ammenities: amenities,
                    site_id: site_Id,
                    country: localStorage.getItem("country")
                })
            }).catch(err => {
                console.log(err);
            })
        }
    }

    //backgroundImage = info.bpBackLogo + "_" + localStorage.getItem("regionCountry") + info.bpBackLogoExt;
    var post = localStorage.getItem("post") !== null ? localStorage.getItem("post") : "";
    var magic = localStorage.getItem("magic") !== null ? localStorage.getItem("magic") : "";

    return (
        <div>

            <Helmet>
                <body className="successbody"></body>
            </Helmet>

            <div class="bpNav">
                <img class="nr-navigation__logo-img" src={info.bpHeadLogo} alt="BP Logo"></img>
            </div>

            <div>
                <div>
                    <div>
                        <div class="cookie-disclaimer-text">
                            {/* {<img class="backgroung_img" width="600" height="400" src={require("../media/SuccessBgrd_" + localStorage.getItem("regionCountry") + info.bpBackLogoExt)} alt="backgroung img" ></img>} */}
                            <img class="backgroung_img"
                                 src={info.bpBackLogo + "_" + localStorage.getItem("regionCountry") + info.bpBackLogoExt}
                                 alt="backgroung img"></img>

                            <div class="welcomeMessage">
                                <div class="welcomHead">{datalanguage["Welcome to bp's WiFi"]}</div>
                                {
                                    connectMsg ? <div>{datalanguage["ToSeePromotions"]}</div> :
                                        <div>{datalanguage["Welcome Msg"]}</div>
                                }

                                {/* {
                  double ? <div><button id="btnConnect" class="connect btnCnt" name="submit" onClick={() => {
                    Hide();
                    handleConnect();
                  }} >{datalanguage["Connect"]}</button></div> : null
                } */}

                                {
                                    btnConnect ? <div>
                                        <button id="btnConnect" class="connect btnCnt" name="submit" onClick={() => {
                                            handleConnect();
                                        }}>{datalanguage["Connect"]}</button>
                                    </div> : null
                                }

                            </div>
                            <script src="/at.js"></script>
                            <div id='AdobeContent'></div>
                        </div>

                        <div className="iframeMistURL">
                            <iframe src={mistURL}>
                            </iframe>
                        </div>

                    </div>
                </div>
            </div>

            <form id="myForm" name="myForm" action={post} method="POST">
                <input type='hidden' name="magic" id="magic" value={magic}/>
                <input type='hidden' name="username" id="username" value={username}/>
                <input type='hidden' name="password" id="password" value="321@F0rTinEt"/>
                <input id="btn" type="submit" name="submit" value="Login" hidden/>
            </form>
        </div>
    )
};

export default Success2;