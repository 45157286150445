import './SuceesPages.css';
import React from 'react';
import {Helmet} from "react-helmet";
import {useLocation} from "react-router-dom";
import data from '../data.json';
import appConfig from '../appConfig.json';

function Success(props) {
    const location = useLocation();
    var urlLanguage = location.pathname;
    urlLanguage = urlLanguage.toLocaleLowerCase();

    //#region Checking users Browser language to display the labels of same language from data.json
    var userLang = localStorage.getItem("language");
    var datalanguage = data[userLang];
    var username = "";
    console.log(userLang);
    let linkExpiry = true;

    if (localStorage.getItem("isConnect") === null) {
        window.location.href = appConfig.appBaseUrl + getLandingPageLaguages(localStorage.getItem("language")) + "/" + localStorage.getItem("device") + "/LandingPage";
    }

    if (localStorage.getItem("device") === "FRN" || localStorage.getItem("device") === "frn") {
        setTimeout(() => {
            if (props.location.state !== undefined) {
                var userName = props.location.state.respdata;
                console.log(props.location.state.respdata);
                localStorage.setItem("isConnect", true);
                document.getElementById("username").value = userName + appConfig.userIdDomainExt;
                ;
                linkExpiry = false;
                document.getElementById("btn").click();
            }
        }, 5000);

        setTimeout(() => {
            window.location.href = appConfig.appBaseUrl + getLandingPageLaguages(localStorage.getItem("language")) + localStorage.getItem("device");
            linkExpiry = true;
        }, appConfig.linkExpiryInMin * 60 * 1000);
    }

    if (localStorage.getItem("device") === "synapse") {
        if (props.location.state !== undefined) {
            //uncomment below line please!!!!
            window.location.open = props.location.state.respdata;

            linkExpiry = false;
            setTimeout(() => {
                window.location.href = appConfig.appBaseUrl + getLandingPageLaguages(localStorage.getItem("language")) + "/" + localStorage.getItem("device") + "/LandingPage";
                linkExpiry = true;
            }, appConfig.linkExpiryInMin * 60 * 1000);
            window.open(props.location.state.respdata);
        } else {
            window.location.href = appConfig.appBaseUrl + getLandingPageLaguages(localStorage.getItem("language")) + "/" + localStorage.getItem("device") + "/LandingPage";
        }
    }

    function getLandingPageLaguages(lang) {
        let language = "";
        switch (lang) {
            case "en":
                language = "en_gb";
                break;
            case "nl":
                language = "nl_nl";
                break;
            case "de":
                language = "de_de";
                break;
            case "fr":
                language = "fr_lu";
                break;
            case "en":
                language = "en_us";
                break;
            case "lu":
                language = "de_lu";
                break;
            default:
                language = "en_gb";
        }
        return language;
    }

    var post = localStorage.getItem("post") !== null ? localStorage.getItem("post") : "";
    var magic = localStorage.getItem("magic") !== null ? localStorage.getItem("magic") : "";

    // window.dataLayer.push({
    //   event: 'LoadFunction',
    //   amenities:'ABC'
    // })
    return (
        <div>
            <Helmet>
                <body className="successbody"></body>
            </Helmet>

            <div style={{verticalAlign: 'middle'}}>
                <div className="containerSuccess">
                    <h1 className="head">{datalanguage["Note the following"] + ":"}</h1><br/>
                    <p className="para">{datalanguage["Email Verification Message"]}
                    </p><br/>
                    <div id='AdobeContent'></div>
                    <script type="text/javascript" src="at.js"></script>
                </div>
            </div>

            <form id="myForm" name="myForm" action={post} method="POST">
                <input type='hidden' name="magic" id="magic" value={magic}/>
                <input type='hidden' name="username" id="username" value={username}/>
                <input type='hidden' name="password" id="password" value="321@F0rTinEt"/>
                <input id="btn" type="submit" name="submit" value="Login" hidden/>
            </form>
        </div>
    )
};

export default Success;