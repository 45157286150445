import './SuceesPages.css';
import React, {useRef, useState} from 'react';
import queryString from 'query-string';
import {useLocation, useNavigate} from "react-router-dom";
import data from '../data.json';
import info from '../appConfig.json';

function LandingPage(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const [checkboxValue, setCheckkbox] = useState(false);
    var errorArr = useRef('CheckBox is required');
    //chamge in data1.json when salesforce onbboarding done
    var baseurl = info.baseurl;
    var authUrl = info.authUrl;
    var clientid = info.clientid;
    var redirecturi = info.redirecturi;
    var oAuthLagunageURL = info.oAuthLagunageURL;

    let tnc = '';
    let prstmt = '';
    let pdfurl = info.pdfurl;
    localStorage.clear();

    const values = queryString.parse(location.search)

    // //#region Checking   language from URL to display the labels of same language from data.json
    var UrlLanguage = location.pathname;
    UrlLanguage = UrlLanguage.toLocaleLowerCase();
    var userLang = "";
    localStorage.setItem("con", "");
    localStorage.setItem("saRedirection", "");

    if (UrlLanguage.indexOf("de_de") !== -1) {
        userLang = "de";
        tnc = "BP_TNC_UK.pdf";
        prstmt = "BP_PrStmt_UK.pdf";
        localStorage.setItem("language", "de");
        localStorage.setItem("tnc", "BP_TNC_UK.pdf");
        localStorage.setItem("prstmt", "BP_PrStmt_UK.pdf");
        localStorage.setItem("country", "Germany");
        localStorage.setItem("Countrycode", "de");
        localStorage.setItem("lanarea", "de_de");
        localStorage.setItem("regionCountry", "DE");
    } else if (UrlLanguage.indexOf("de_lu") !== -1) {
        userLang = "de";
        tnc = "BP_TNC_UK.pdf";
        prstmt = "BP_PrStmt_UK.pdf";
        localStorage.setItem("language", "de");
        localStorage.setItem("tnc", "BP_TNC_UK.pdf");
        localStorage.setItem("prstmt", "BP_PrStmt_UK.pdf");
        localStorage.setItem("country", "Germany");
        localStorage.setItem("Countrycode", "de");
        localStorage.setItem("lanarea", "de_lu");
        localStorage.setItem("regionCountry", "LU");
    } else if (UrlLanguage.indexOf("en_gb") !== -1) {
        userLang = "en";
        tnc = "BP_TNC_UK.pdf";
        prstmt = "BP_PrStmt_UK.pdf";
        localStorage.setItem("language", "en");
        localStorage.setItem("tnc", "BP_TNC_UK.pdf");
        localStorage.setItem("prstmt", "BP_PrStmt_UK.pdf");
        localStorage.setItem("country", "UK");
        localStorage.setItem("Countrycode", "gb");
        localStorage.setItem("lanarea", "en_gb");
        localStorage.setItem("regionCountry", "UK");
    } else if (UrlLanguage.indexOf("en_za") !== -1) {
        userLang = "en";
        tnc = "BP_TNC_ZA.pdf";
        prstmt = "BP_PrStmt_ZA.pdf";
        localStorage.setItem("language", "en");
        localStorage.setItem("tnc", "BP_TNC_ZA.pdf");
        localStorage.setItem("prstmt", "BP_PrStmt_ZA.pdf");
        localStorage.setItem("country", "SouthAfrica");
        localStorage.setItem("Countrycode", "gb");
        localStorage.setItem("saRedirection", "za");
        localStorage.setItem("lanarea", "en_za");
        localStorage.setItem("regionCountry", "ZA");
    } else if (UrlLanguage.indexOf("en_nz") !== -1) {
        userLang = "en";
        tnc = "BP_TNC_NZ.pdf";
        prstmt = "BP_PrStmt_UK.pdf";
        localStorage.setItem("language", "en");
        localStorage.setItem("tnc", "BP_TNC_NZ.pdf");
        localStorage.setItem("prstmt", "BP_PrStmt_UK.pdf");
        localStorage.setItem("country", "New Zealand");
        localStorage.setItem("Countrycode", "nz");
        localStorage.setItem("lanarea", "en_nz");
        localStorage.setItem("regionCountry", "NZ");
    } else if (UrlLanguage.indexOf("nl_nl") !== -1) {
        userLang = "nl";
        tnc = "BP_TNC_NL.pdf";
        prstmt = "BP_PrStmt_NL.pdf";
        localStorage.setItem("language", "nl");
        localStorage.setItem("tnc", "BP_TNC_NL.pdf");
        localStorage.setItem("prstmt", "BP_PrStmt_NL.pdf");
        localStorage.setItem("country", "Netherland");
        localStorage.setItem("Countrycode", "nl");
        localStorage.setItem("lanarea", "nl_nl");
        localStorage.setItem("regionCountry", "NL");
    } else if ((UrlLanguage.indexOf("en_us") !== -1)) {
        userLang = "en";
        tnc = "BP_TNC_US.pdf";
        prstmt = "BP_PrStmt_UK.pdf";
        localStorage.setItem("language", "en");
        localStorage.setItem("tnc", "BP_TNC_US.pdf");
        localStorage.setItem("prstmt", "BP_PrStmt_UK.pdf");
        localStorage.setItem("country", "US");
        localStorage.setItem("Countrycode", "us");
        localStorage.setItem("lanarea", "en_es");
        localStorage.setItem("regionCountry", "US");
    } else if ((UrlLanguage.indexOf("fr_lu") !== -1)) {
        userLang = "fr";
        tnc = "BP_TNC_UK.pdf";
        prstmt = "BP_PrStmt_UK.pdf";
        localStorage.setItem("language", "fr");
        localStorage.setItem("tnc", "BP_TNC_UK.pdf");
        localStorage.setItem("prstmt", "BP_PrStmt_UK.pdf");
        localStorage.setItem("country", "France");
        localStorage.setItem("Countrycode", "fr");
        localStorage.setItem("lanarea", "fr_lu");
        localStorage.setItem("regionCountry", "LU");
    } else {
        userLang = "en";
        tnc = "BP_TNC_UK.pdf";
        prstmt = "BP_PrStmt_UK.pdf";
        localStorage.setItem("language", "en");
        localStorage.setItem("tnc", "BP_TNC_UK.pdf");
        localStorage.setItem("prstmt", "BP_PrStmt_UK.pdf");
        localStorage.setItem("country", "UK");
        localStorage.setItem("Countrycode", "gb");
        localStorage.setItem("lanarea", "en_gb");
        localStorage.setItem("regionCountry", "UK");
    }

    var datalanguage = data[userLang];
    // var parameters = "?response_type=code&client_id=" + clientid + "&redirect_uri=" + redirecturi;
    var stateValue = localStorage.getItem("language") + getNetwork(localStorage.getItem("device"));
    var parameters = "?response_type=code&&display=popup&client_id=" + clientid + "&redirect_uri=" + redirecturi;

    //var parameters = "?response_type=code&client_id=" + clientid + "&redirect_uri=" + redirecturi + "&scope=A";
    console.log(baseurl + authUrl + parameters);
    console.log(encodeURIComponent(baseurl + authUrl + parameters));
    console.log(baseurl + oAuthLagunageURL.replace("idpLang", localStorage.getItem("language")) + oAuthLagunageURL.replace("stateParameter", stateValue) + encodeURIComponent(authUrl + parameters))
    //URLENCODE(/services/oauth2/authorize?response_type=code&client_id=3MVG9LlLrkcRhGHadXzO0jRQX7ku_tScMpP3rGulHGal9kb0BSp9enVWXSoY72vWUMp.0bjsHmPhozQXCwb1j&redirect_uri=http://localhost:3000/auth/callback&scope=A)
    var fortinetorJuniperString = location.pathname;
    fortinetorJuniperString = fortinetorJuniperString.toLocaleLowerCase();
    localStorage.setItem("urlpath", location.pathname + location.search);
    if (fortinetorJuniperString.includes("FRN") || fortinetorJuniperString.includes("frn")) {
        localStorage.setItem("device", "frn");
        localStorage.setItem("post", values.post);
        localStorage.setItem("magic", values.magic);
        localStorage.setItem("ap_name", values.ap_name);
        localStorage.setItem("usermac", values.usermac);
        localStorage.setItem("ssid", values.ssid);
        localStorage.setItem("apname", values.apname);
    }

    if (fortinetorJuniperString.includes("synapse") || fortinetorJuniperString.includes("SYNAPSE")) {
        localStorage.setItem("device", "synapse");
        localStorage.setItem("ap_mac", values.ap_mac);
        localStorage.setItem("ap_name", values.ap_name);
        localStorage.setItem("wlan_id", values.wlan_id);
        localStorage.setItem("client_mac", values.client_mac);
        localStorage.setItem("forward", values.url);
    }

    //This will be the callback once salesforce onboardin is completed
    //var redirecturi= info.redirecturi1+"/"+localStorage.getItem("countrycode")+"/"+ localStorage.getItem("device")+"/auth/calbackl"

    var handleLogin = e => {
        // Only english language for IDP Login
        //window.location.href = baseurl + authUrl + parameters;
        // Language selection based on URL for IDP Login
        let setLaguage = "";
        let loginURL = "";
        let lagunageParameter = "";
        let expId = info.appName + getLanguageArea(localStorage.getItem("lanarea")) + getSaleforceConfigNetworkValue(localStorage.getItem("device")) + info.envexpid;
        // localStorage.setItem("isConnect", true);
        if (localStorage.getItem("language"))
            setLaguage = getLoginLaguages(localStorage.getItem("language"));
        lagunageParameter = oAuthLagunageURL.replace("idpLang", setLaguage);
        lagunageParameter = lagunageParameter.replace("stateParameter", stateValue);
        lagunageParameter = lagunageParameter.replace("regionExpId", info.appName + getLanguageArea(localStorage.getItem("lanarea")) + getSaleforceConfigNetworkValue(localStorage.getItem("device")) + info.envexpid);

        //loginURL = baseurl + lagunageParameter + encodeURIComponent(authUrl.replace("exp_id", expId) + parameters);

        loginURL = baseurl + authUrl.replace("exp_id", expId) + parameters;
        console.log(loginURL);

        //window.location.href = loginURL;

        window.location.href = redirecturi;

        localStorage.setItem("redirectedfromLogin", "true");
        localStorage.setItem("isConnect", "");
        //window.location.href = baseurl + oAuthLagunageURL.replace("idpLang", localStorage.getItem("language")) + encodeURIComponent(authUrl + parameters);
        console.log(baseurl + authUrl + parameters);
        console.log(baseurl + oAuthLagunageURL.replace("idpLang", setLaguage) + encodeURIComponent(authUrl + parameters));
    }

    function getLoginLaguages(lang) {
        let language = "";
        switch (lang) {
            case "en":
                language = "en_US";
                break;
            case "nl":
                language = "nl_NL";
                break;
            case "de":
                language = "de";
                break;
            case "fr":
                language = "fr";
                break;
            default:
                language = "en_US";
        }
        return language;
    }

    function getNetwork(net) {
        let reNet = "";
        switch (net) {
            case "synapse":
                reNet = "sys";
                break;
            case "frn":
                reNet = "frn";
                break;
        }
        return reNet;
    }

    function getSaleforceConfigNetworkValue(net) {
        let reNet = "";
        switch (net) {
            case "synapse":
                reNet = "syn";
                break;
            case "frn":
                reNet = "for";
                break;
        }
        return reNet;
    }

    function getLanguageArea(la) {
        let lanArea = "";
        lanArea = la.replace("_", "");
        return lanArea;
    }

    var handleRegister = () => {
        navigate(
            location.pathname.split('/').slice(0, -1).join('/') + '/Registration' + location.search
        );
    }

    var handleForgotPwd = () => {
        if ((UrlLanguage.indexOf("de_de") != -1) || (UrlLanguage.indexOf("de_lu") != -1)) {
            userLang = "de";
            localStorage.setItem("language", "de");
            localStorage.setItem("country", "Germany");
            localStorage.setItem("Countrycode", "de");
            localStorage.setItem("lanarea", "de_de");
        } else if (UrlLanguage.indexOf("en_gb") != -1) {
            userLang = "en";
            localStorage.setItem("language", "en");
            localStorage.setItem("country", "UK");
            localStorage.setItem("Countrycode", "gb");
            localStorage.setItem("lanarea", "en_gb");
        } else if (UrlLanguage.indexOf("en_us") != -1) {
            userLang = "en";
            localStorage.setItem("language", "en");
            localStorage.setItem("country", "US");
            localStorage.setItem("Countrycode", "us");
            localStorage.setItem("lanarea", "en_us");
        }
         else if (UrlLanguage.indexOf("en_za") != -1) {
            userLang = "en";
            localStorage.setItem("language", "en");
            localStorage.setItem("country", "SouthAfrica");
            localStorage.setItem("Countrycode", "gb");
            localStorage.setItem("saRedirection", "za");
            localStorage.setItem("lanarea", "en_za");
        } else if (UrlLanguage.indexOf("nl_nl") != -1) {
            userLang = "nl";
            localStorage.setItem("language", "nl");
            localStorage.setItem("country", "Netherland");
            localStorage.setItem("Countrycode", "nl");
            localStorage.setItem("lanarea", "nl_nl");
        } else if ((UrlLanguage.indexOf("fr_lu") != -1)) {
            userLang = "fr";
            localStorage.setItem("language", "fr");
            localStorage.setItem("country", "France");
            localStorage.setItem("Countrycode", "fr");
            localStorage.setItem("lanarea", "fr_lu");
        } else {
            userLang = "en";
            localStorage.setItem("language", "en");
            localStorage.setItem("country", "UK");
            localStorage.setItem("Countrycode", "gb");
            localStorage.setItem("lanarea", "en_gb");
        }
        let setLaguage = "";
        if (localStorage.getItem("language"))
            setLaguage = getLoginLaguages(localStorage.getItem("language"));

        let forgotURL = baseurl + info.oAuthForgotPwd.replace("idpLang", setLaguage);
        window.location.href = forgotURL;
    }

    var handleForgotcss = () => {
        let className = "forgotlink";
        return className;
    }

    var handleCheckbox = e => {
        e.target.checked === false ? errorArr.current = 'CheckBox is required' : errorArr.current = '';
        setCheckkbox(errorArr.current);
        console.log(errorArr.current);
    }

    //To disable or enable the Registration button
    const handleDisable = () => {
        console.log('fom handle disableemail', errorArr.current.errEmail);
        console.log(errorArr.current);
        return errorArr.current;
    }

    return (
        <div className='form-container'>
            <form>
                <div className='BPLogo'>
                    <img src={info.bpLogo} alt="BP Logo" className='imgLogo imgLogoWidth'></img>
                    {/* <img src={require('../media/bp-logo_captive.svg')} className='imgLogo'></img> */}
                </div>

                <div className="col-lg-12">
                    <div className='form-group'>
                        <label>{datalanguage["By clicking Login, you agree to our latest"]} <a
                            href={pdfurl + localStorage.getItem("tnc")}
                            target="_blank" rel="noreferrer">{datalanguage["Terms and Conditions"]} </a>{datalanguage["and that you have read our"]}
                            <a href={pdfurl + prstmt}
                               target="_blank" rel="noreferrer"> {datalanguage["Privacy Policy"]} </a>{datalanguage["have read"]}</label>
                    </div>
                    <button id="login" className="btn btn-success btn-lg btn-block btnCustom" type="button"
                            onClick={handleLogin}>{datalanguage["Login"]}</button>

                    {/* <div class="nav">
            <div class="Noacc">
              <label className="noaccyet">{datalanguage["No account yet"]}?</label>
            </div>

            <div class="registerhere">
              <a href="#" className="registerlink" onClick={handleRegister}>{datalanguage["Register here"]}</a>
            </div>
          </div> */}

                </div>
            </form>
        </div>
    );
}

export default LandingPage;