import React, {useRef, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import axios from 'axios';
import queryString from 'query-string';
import data from '../data.json';
import info from '../appConfig.json';

//Creating a component which can be reuse and contain divs and inputs
var TextComponent = props => {
    const {errorMsg, ...prop} = props;
    //console.log(errorMsg);
    console.log(props);
    return (<div className="form-group">
        <input className="form-control form-control-lg customtext" {...prop} />
        <div>{errorMsg}</div>
    </div>);
}

//Here starts the functional component
function Registration(props) {
    const [firstName, setFname] = useState("");
    const [lastName, setLname] = useState("");
    const [email, setEmail] = useState("");
    var errorArr = useRef({errFname: '', errLname: '', errEmail: '', errChkbox: 'CheckBox is required'});
    const [errorMessage, setErrMessage] = useState("");
    const [checkboxValue, setCheckkbox] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    var inProcess = useRef('');
    const [isinProcess, setProcess] = useState("");
    var databody = "";

    let pdfurl = info.pdfurl;
    // //#region Checking users Browser language to display the labels of same language from data.json
    //var userLang = navigator.language || navigator.userLanguage;
    var UrlLanguage = location.pathname;
    UrlLanguage = UrlLanguage.toLocaleLowerCase();
    var userLang = "";
    var isTandC = false;
    localStorage.clear();
    var lambdaFunctionName = "";

    var tnc = localStorage.getItem("tnc");
    var prstmt = localStorage.getItem("prstmt");

    if ((UrlLanguage.indexOf("de_de") != -1) || (UrlLanguage.indexOf("de_lu") != -1)) {
        userLang = "de";
        tnc = "BP_TNC_UK.pdf";
        prstmt = "BP_PrStmt_UK.pdf";
        localStorage.setItem("language", "de");
        localStorage.setItem("tnc", "BP_TNC_UK.pdf");
        localStorage.setItem("prstmt", "BP_PrStmt_UK.pdf");
        localStorage.setItem("country", "Germany");
        localStorage.setItem("Countrycode", "de");
        localStorage.setItem("lanarea", "de_de");
    } else if (UrlLanguage.indexOf("en_gb") != -1) {
        userLang = "en";
        tnc = "BP_TNC_UK.pdf";
        prstmt = "BP_PrStmt_UK.pdf";
        localStorage.setItem("language", "en");
        localStorage.setItem("tnc", "BP_TNC_UK.pdf");
        localStorage.setItem("prstmt", "BP_PrStmt_UK.pdf");
        localStorage.setItem("country", "UK");
        localStorage.setItem("Countrycode", "gb");
        localStorage.setItem("lanarea", "en_gb");
    } else if (UrlLanguage.indexOf("en_za") != -1) {
        userLang = "en";
        tnc = "BP_TNC_ZA.pdf";
        prstmt = "BP_PrStmt_ZA.pdf";
        localStorage.setItem("language", "en");
        localStorage.setItem("tnc", "BP_TNC_ZA.pdf");
        localStorage.setItem("prstmt", "BP_PrStmt_ZA.pdf");
        localStorage.setItem("country", "SouthAfrica");
        localStorage.setItem("Countrycode", "za");
        localStorage.setItem("lanarea", "en_za");
    } else if ((UrlLanguage.indexOf("en_us") !== -1)) {
        userLang = "en";
        tnc = "BP_TNC_US.pdf";
        prstmt = "BP_PrStmt_UK.pdf";
        localStorage.setItem("language", "en");
        localStorage.setItem("tnc", "BP_TNC_US.pdf");
        localStorage.setItem("prstmt", "BP_PrStmt_UK.pdf");
        localStorage.setItem("country", "US");
        localStorage.setItem("Countrycode", "us");
        localStorage.setItem("lanarea", "en_es");
        localStorage.setItem("regionCountry", "US");
    } else if (UrlLanguage.indexOf("en_nz") != -1) {
        userLang = "en";
        tnc = "BP_TNC_NZ.pdf";
        prstmt = "BP_PrStmt_NZ.pdf";
        localStorage.setItem("language", "en");
        localStorage.setItem("tnc", "BP_TNC_NZ.pdf");
        localStorage.setItem("prstmt", "BP_PrStmt_NZ.pdf");
        localStorage.setItem("country", "NewZealand");
        localStorage.setItem("Countrycode", "nz");
        localStorage.setItem("lanarea", "en_nz");
    } else if (UrlLanguage.indexOf("nl_nl") != -1) {
        userLang = "nl";
        tnc = "BP_TNC_NL.pdf";
        prstmt = "BP_PrStmt_NL.pdf";
        localStorage.setItem("language", "nl");
        localStorage.setItem("tnc", "BP_TNC_NL.pdf");
        localStorage.setItem("prstmt", "BP_PrStmt_NL.pdf");
        localStorage.setItem("country", "Nederland");
        localStorage.setItem("Countrycode", "nl");
        localStorage.setItem("lanarea", "nl_nl");
    } else if (UrlLanguage.indexOf("fr_lu") != -1) {
        userLang = "fr";
        tnc = "BP_TNC_UK.pdf";
        prstmt = "BP_PrStmt_UK.pdf";
        localStorage.setItem("language", "fr");
        localStorage.setItem("tnc", "BP_TNC_UK.pdf");
        localStorage.setItem("prstmt", "BP_PrStmt_UK.pdf");
        localStorage.setItem("country", "France");
        localStorage.setItem("Countrycode", "fr");
        localStorage.setItem("lanarea", "fr_lu");
    } else {
        userLang = "en";
        tnc = "BP_TNC_UK.pdf";
        prstmt = "BP_PrStmt_UK.pdf";
        localStorage.setItem("language", "en");
        localStorage.setItem("tnc", "BP_TNC_UK.pdf");
        localStorage.setItem("prstmt", "BP_PrStmt_UK.pdf");
        localStorage.setItem("country", "UK");
        localStorage.setItem("Countrycode", "gb");
    }

    var datalanguage = data[userLang];
    const values = queryString.parse(location.search)
    console.log(userLang);

    var fortinetorJuniperString = location.pathname;

    fortinetorJuniperString = fortinetorJuniperString.toLocaleLowerCase();
    if (fortinetorJuniperString.includes("FRN") || fortinetorJuniperString.includes("frn")) {
        localStorage.setItem("device", "frn");
        localStorage.setItem("post", values.post);
        localStorage.setItem("magic", values.magic);
    }

    if (fortinetorJuniperString.includes("synapse") || fortinetorJuniperString.includes("SYNAPSE")) {
        localStorage.setItem("device", "synapse");
        localStorage.setItem("ap_mac", values.ap_mac);
        localStorage.setItem("ap_name", values.ap_name);
        localStorage.setItem("wlan_id", values.wlan_id);
        localStorage.setItem("client_mac", values.client_mac);
        localStorage.setItem("forward", values.url);
    }

    function getNetwork(net) {
        let reNet = "";
        switch (net) {
            case "synapse":
                reNet = "syn";
                break;
            case "frn":
                reNet = "for";
                break;
        }
        return reNet;
    }

    function getLanguageArea(la) {
        let lanArea = "";
        lanArea = la.replace("_", "");
        return lanArea;
    }

    //#endregion

    //Change event for First Name text box
    var handleFname = e => {
        e.target.value === '' ? errorArr.current.errFname = datalanguage["First Name is required"] : errorArr.current.errFname = '';
        setFname(e.target.value);
        console.log('in handle first name');
    }

    //Change event for Last Name text box
    var handleLname = e => {
        e.target.value === '' ? errorArr.current.errLname = datalanguage["Last Name is required"] : errorArr.current.errLname = '';
        setLname(e.target.value);
        console.log('in handle last name');
    }
    //Change event for Email text box

    var handleEmail = e => {
        e.target.value === '' ? errorArr.current.errEmail = datalanguage["Email is required"] : ((!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(e.target.value)) ? errorArr.current.errEmail = datalanguage['Email is invalid'] : errorArr.current.errEmail = '');
        setEmail(e.target.value);
        console.log('in handle Email');
    }

    //Change event for Checkbox
    var handleCheckbox = e => {
        e.target.checked === false ? errorArr.current.errChkbox = 'CheckBox is required' : errorArr.current.errChkbox = '';
        setCheckkbox(errorArr.current.errChkbox);
        if (e.target.checked === true)
            localStorage.setItem("isTandC", true);
        else
            localStorage.setItem("isTandC", false);
        console.log(errorArr.current.errChkbox);
    }

    //To disable or enable the Registration button
    const handleDisable = () => {
        console.log('fom handle disableemail', errorArr.current.errEmail);
        console.log(errorArr.current.errEmail || errorArr.current.errLname || errorArr.current.errFname || errorArr.current.errChkbox);
        return errorArr.current.errEmail || errorArr.current.errLname || errorArr.current.errFname || errorArr.current.errChkbox || inProcess.current;
    }

    //Click event of Registration button
    var handleSubmit = async () => {
        console.log(firstName, lastName, email);
        //#region Validate the input fields...
        if (firstName === "" || lastName === "" || email === "" || (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email))) {
            if (firstName === "") {
                errorArr.current.errFname = datalanguage["First Name is required"]
            }
            if (lastName === "") {
                errorArr.current.errLname = datalanguage["Last Name is required"]
            }
            if (email === "") {
                errorArr.current.errEmail = datalanguage["Email is required"]
            } else if ((!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email))) {
                errorArr.current.errEmail = datalanguage['Email is invalid'];
            }
            setErrMessage(datalanguage["Please fill all the mandatory fields"]);
            return;
        }

        //#endregion
        //After the filelds are validated successfully, disable the button....
        inProcess.current = 'True';
        setProcess("true");
        ////#region Check from URL if the device is fortinet or Juniper and redirect accordingly
        if (localStorage.getItem("device") === "frn") {
            databody = {
                "firstname": firstName,
                "lastname": lastName,
                "email": email,
                "tcandp": Boolean(localStorage.getItem("isTandC")),
                "appName": info.appName + getLanguageArea(localStorage.getItem("lanarea")),
                "returnApp": info.appName + getLanguageArea(localStorage.getItem("lanarea")) + getNetwork(localStorage.getItem("device")) + info.envexpid,
                "language": getRegistrationLaguages(),
                "functionName": "Registration",
                "device": "Fortinet",
                "env": info.environment,
                "userIdDomainExt": info.userIdDomainExt
            }
            console.log(databody);
        }

        if (localStorage.getItem("device") === "synapse") {
            var expires = Math.round(new Date().getTime() / 1000) + 240;
            databody = {
                "firstname": firstName,
                "lastname": lastName,
                "email": email,
                "tcandp": Boolean(localStorage.getItem("isTandC")),
                "appName": info.appName + getLanguageArea(localStorage.getItem("lanarea")),
                "returnApp": info.appName + getLanguageArea(localStorage.getItem("lanarea")) + getNetwork(localStorage.getItem("device")) + info.envexpid,
                "language": getRegistrationLaguages(),
                "functionName": "Registration",
                "countrycode": localStorage.getItem("Countrycode"),
                "device": "Juniper",
                "env": info.environment,
                "userIdDomainExt": info.userIdDomainExt,
                "payload": {
                    "ap_mac": localStorage.getItem("ap_mac"),
                    "wlan_id": localStorage.getItem("wlan_id"),
                    "client_mac": localStorage.getItem("client_mac"),
                    "minutes": 10,
                    "expires": parseInt(expires),
                    "forward": info.appBaseUrl + getRegistrationLaguages() + "/synapse/verifyemail",
                    "email": email,
                    "authorize_only": false
                }
            }
            console.log(databody);
        }

        function getRegistrationLaguages() {
            let lang = localStorage.getItem("language");
            let language = "";
            switch (lang) {
                case "en":
                    language = "en";
                    break;
                case "nl":
                    language = "nl_NL";
                    break;
                case "de":
                    language = "de";
                    break;
                case "fr":
                    language = "fr";
                    break;
                default:
                    language = "en";
            }
            return language;
        }

        if (info.environment == "dev" || info.environment == "dev") {
            lambdaFunctionName = "";
        } else if (info.environment == "preprod" || info.environment == "prod") {
            //lambdaFunctionName = databody.functionName;
            lambdaFunctionName = "";
        }

        //#endregion
        //#region After the filelds are validated successfully , make request to  Lamda function through axios
        var response = await axios.post(info.lambdaUrl + lambdaFunctionName,
            databody).then(resp => {
            console.log(resp);
            if (resp.data.statusCode === 209) {
                console.log(inProcess.current);
                inProcess.current = '';
                console.log(inProcess.current)
                setErrMessage("A user with this email already exists");
                setProcess("false");
            } else if (resp.data.statusCode === 200) {
                console.log(resp);
                inProcess.current = '';
                setProcess("false");
                localStorage.setItem("isConnect", "");
                navigate(
                    location.pathname.split('/').slice(0, -1).join('/') + '/verifyemail' + location.search,
                    { state: { respdata: resp.data.body } }
                );
            } else if (resp.data.statusCode === 400) {
                console.log(resp);
            } else {
                setErrMessage(datalanguage["LambdaError"]);
            }
        }).catch(err => {
            console.log(err);
        })
    }
    //#endregion

    return (
        <div className='form-container'>
            {/* <Target data-mbox="testMbox">
      </Target> */}
            <form>
                <div className='BPLogo'>
                    <img src={info.bpLogo} alt="BP Logo" className='imgLogo imgLogoWidth'></img>
                    {/* <img src={require('../BPLogo.png')} className='imgLogo'></img> */}
                </div>

                <div id="popupdiv" className="errorMessage">{errorMessage}</div>
                <TextComponent type='Text' value={firstName} onChange={handleFname}
                               placeholder={datalanguage['First Name']} id='txtfirstName'
                               errorMsg={errorArr.current.errFname}></TextComponent>
                <TextComponent type='Text' value={lastName} onChange={handleLname}
                               placeholder={datalanguage['Last Name']} id='txtlastName'
                               errorMsg={errorArr.current.errLname}></TextComponent>
                <TextComponent type='Email' value={email} onChange={handleEmail} placeholder={datalanguage['Email']}
                               id='txtEmail' errorMsg={errorArr.current.errEmail}></TextComponent>

                <div className="col-lg-12">
                    <div className='form-group'>
                        <input type='checkbox' onChange={handleCheckbox} value={checkboxValue}
                               id='chkTerms'></input> {datalanguage["By clicking"]} "{datalanguage["Sign Up"]}", {datalanguage["you agree to BP's"]}
                        <a href={pdfurl + tnc}
                           target="_blank" rel="noreferrer">{datalanguage["Terms and Conditions"]} </a>{datalanguage["and"]} <a
                        href={pdfurl + prstmt} target="_blank" rel="noreferrer"> {datalanguage["Privacy Policy"]} </a>
                    </div>
                    <input type="hidden" id="magic"/>
                    <input type="hidden" id="post"/>
                    <button id="submit" type="button" disabled={handleDisable()}
                            className="btn btn-success btn-block btnCustom"
                            onClick={handleSubmit}> {datalanguage["Sign Up"]} </button>
                    <div className='setAccount'>
                        <Link to={{
                            pathname: location.pathname.split('/').slice(0, -1).join('/') + '/LandingPage',
                            search: location.search
                        }} className='linkColor'>{datalanguage["Go Back"]}</Link>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default Registration;