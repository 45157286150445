import './SuceesPages.css';
import React from 'react';
import {Helmet} from "react-helmet";
import TagManager from 'react-gtm-module';
import queryString from 'query-string';
import {useLocation} from "react-router-dom";
import axios from 'axios';
import data from '../data.json';

function Success(props) {
    var databody = "";

    let amenities = "";

    const tagManagerArgs = {
        gtmId: "GTM-MCJVC79"
    }
    const location = useLocation();
    var userLang = localStorage.getItem("language");
    var datalanguage = data[userLang];
    var username = props.location.state === undefined ? "" : props.location.state.username;
    console.log(userLang);
    if (props.location.state !== undefined)
        console.log(props.location.state.JuniperURL)
    const values = queryString.parse(location.search)
    var id = values.ap_name;
    console.log(values.code)
    //id=DEBP200650000-ap01

    databody = {
        "functionName": "Success",
        "siteId": "DEBP200650000"
    }

    var callSnow = async () => {

        var response = await axios.post("https://g5eyoc0w5h.execute-api.ap-south-1.amazonaws.com/default/splashpage3", databody
        ).then(resp => {
            console.log(resp);
            amenities = resp.data.body;
            TagManager.initialize(tagManagerArgs)
            window.dataLayer.push({
                event: 'LoadJS',
                site_ammenities: amenities,
                site_id: "200650000",
                country: "Germany"

            })

        }).catch(err => {
            console.log(err);
        })
    }


    //console.log(values);
    if (location.pathname.includes("FRN")) {
        setTimeout(() => {
            if (props.location.state !== undefined) {
                callSnow();
                //document.getElementById("btn").click();
            }
        }, 500);
    }

    if (location.pathname.includes("synapse")) {
        localStorage.setItem("authcode", values.code);
        setTimeout(() => {
            if (localStorage.getItem("JuniperURL") !== "null") {
                console.log("I am not null");
                callSnow();
                localStorage.setItem("JuniperURL", "null");
                if (props.location.state !== undefined)
                    window.location.href = props.location.state.JuniperURL;
                //  window.open(props.location.state.JuniperURL);
            } else {
                callSnow();

            }

        }, 500);

    }

    if (localStorage.getItem("authcode") != null && localStorage.getItem("authcode") !== "null") {

        //   var response=await axios.post("https://m5gcesvf3f.execute-api.ap-south-1.amazonaws.com/default/Splashpagebpwifi2",databody
        //   ).then(resp=>{
        //     console.log(resp);

        // }).catch(err=>{
        //       console.log(err);
        //    })
    }

    // make call to lambda
    const post = localStorage.getItem("post") !== null ? localStorage.getItem("post") : "";
    const magic = localStorage.getItem("magic") !== null ? localStorage.getItem("magic") : "";


    return (
        <div>
            <Helmet>
                <body className="successbody"></body>
            </Helmet>
            <div style={{verticalAlign: 'middle'}}>
                <div className="containerSuccess">
                    <h1 className="head">{datalanguage['Welcome Message']}</h1><br/>
                    <p className="para">

                    </p><br/>
                    <script src="/at.js"></script>
                    <div id='AdobeContent'></div>
                </div>
            </div>
            <form id="myForm" name="myForm" action={post} method="POST">
                <input type='hidden' name="magic" id="magic" value={magic}/>
                <input type='hidden' name="username" id="username" value={username}/>
                <input type='hidden' name="password" id="password" value="321@F0rTinEt"/>
                <input id="btn" type="submit" name="submit" value="Login" hidden/>

            </form>
        </div>

    )
};

export default Success;