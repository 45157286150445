import React, {useRef, useState} from 'react';
import '../Login.css';
import data from '../data.json';
import info from '../appConfig.json';
import axios from 'axios';
import {Link, useLocation, useNavigate} from "react-router-dom";
import queryString from 'query-string'

//Creating a component which can be reuse and contain divs and inputs
var TextComponent = props => {
    const {errorMsg, ...prop} = props;

    //console.log(errorMsg);
    //console.log(props);
    return (<div className="form-group">
        <input className="form-control form-control-lg customtext" autoComplete="on" {...prop} />
        <div style={{color: 'black'}}>{errorMsg}</div>
    </div>);
}

//Here starts the functional component
function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    var errorArr = useRef({errEmail: '', errPassword: '', errChkbox: 'CheckBox is required'});
    const [errorMessage, setErrMessage] = useState("");
    const [checkboxValue, setCheckbox] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    var inProcess = useRef('');
    const [isinProcess, setProcess] = useState("");
    console.log(navigate)
    console.log(location.search);
    const values = queryString.parse(location.search)
    console.log(values);
    console.log(values.ap_mac);
    let tnc = '';
    let prstmt = '';
    let pdfurl = info.pdfurl;
    //let pdfurl = "http://splashpagesbpwifi.s3-website.ap-south-1.amazonaws.com/splashpages/static/media/";
    var pathPrefix = "";
    var pathSuccess = "";
    // //#region Checking users Browser language to display the labels of same language from data.json
    var UrlLanguage = location.pathname;
    var userLang = "";

    if (UrlLanguage.indexOf("de_de") !== -1) {
        userLang = "de";
        tnc = "BP_TNC_UK.pdf";
        prstmt = "BP_PrStmt_UK.pdf";
        localStorage.setItem("language", "de");
        localStorage.setItem("tnc", "BP_TNC_UK.pdf");
        localStorage.setItem("prstmt", "BP_PrStmt_UK.pdf");
        localStorage.setItem("country", "Germany");
        localStorage.setItem("Countrycode", "DE");
    } else if (UrlLanguage.indexOf("en_gb") !== -1 || UrlLanguage.indexOf("en_za") !== -1) {
        userLang = "en";
        tnc = "BP_TNC_UK.pdf";
        prstmt = "BP_PrStmt_UK.pdf";
        localStorage.setItem("language", "en");
        localStorage.setItem("tnc", "BP_TNC_UK.pdf");
        localStorage.setItem("prstmt", "BP_PrStmt_UK.pdf");
        localStorage.setItem("country", "UK");
        localStorage.setItem("Countrycode", "GB");
    } else if (UrlLanguage.indexOf("en_za") !== -1) {
        userLang = "en";
        tnc = "BP_TNC_ZA.pdf";
        prstmt = "BP_PrStmt_ZA.pdf";
        localStorage.setItem("language", "en");
        localStorage.setItem("tnc", "BP_TNC_ZA.pdf");
        localStorage.setItem("prstmt", "BP_PrStmt_ZA.pdf");
        localStorage.setItem("country", "SouthAfrica");
        localStorage.setItem("Countrycode", "ZA");
    } else if ((UrlLanguage.indexOf("en_us") !== -1)) {
        userLang = "en";
        tnc = "BP_TNC_US.pdf";
        prstmt = "BP_PrStmt_UK.pdf";
        localStorage.setItem("language", "en");
        localStorage.setItem("tnc", "BP_TNC_US.pdf");
        localStorage.setItem("prstmt", "BP_PrStmt_UK.pdf");
        localStorage.setItem("country", "US");
        localStorage.setItem("Countrycode", "US");
    } else if (UrlLanguage.indexOf("en_nz") !== -1) {
        userLang = "en";
        tnc = "BP_TNC_NZ.pdf";
        prstmt = "BP_PrStmt_NZ.pdf";
        localStorage.setItem("language", "en");
        localStorage.setItem("tnc", "BP_TNC_NZ.pdf");
        localStorage.setItem("prstmt", "BP_PrStmt_NZ.pdf");
        localStorage.setItem("country", "NewZealand");
        localStorage.setItem("Countrycode", "NZ");
    } else if (UrlLanguage.indexOf("nl_nl") !== -1) {
        userLang = "nl";
        tnc = "BP_TNC_NL.pdf";
        prstmt = "BP_PrStmt_NL.pdf";
        localStorage.setItem("language", "nl");
        localStorage.setItem("tnc", "BP_TNC_NL.pdf");
        localStorage.setItem("prstmt", "BP_PrStmt_NL.pdf");
        localStorage.setItem("country", "Nederland");
        localStorage.setItem("Countrycode", "NL");
    } else if ((UrlLanguage.indexOf("fr_lu") !== -1) || (UrlLanguage.indexOf("de_lu") !== -1)) {
        userLang = "fr";
        tnc = "BP_TNC_UK.pdf";
        prstmt = "BP_PrStmt_UK.pdf";
        localStorage.setItem("language", "fr");
        localStorage.setItem("tnc", "BP_TNC_UK.pdf");
        localStorage.setItem("prstmt", "BP_PrStmt_UK.pdf");
        localStorage.setItem("country", "France");
        localStorage.setItem("Countrycode", "FR");
    } else {
        userLang = "en";
        tnc = "BP_TNC_UK.pdf";
        prstmt = "BP_PrStmt_UK.pdf";
        localStorage.setItem("language", "en");
        localStorage.setItem("tnc", "BP_TNC_UK.pdf");
        localStorage.setItem("prstmt", "BP_PrStmt_UK.pdf");
        localStorage.setItem("country", "UK");
        localStorage.setItem("Countrycode", "GB");
    }

    var datalanguage = data[userLang];
    console.log(userLang);
    //data[userlang]=data[nl]
    //#endregion
    //  const [errorArr,setError]=useState({errEmail:'User Name is required.',errPassword:'Password is required.'});

    //To disable or enable the login button
    const handleDisable = () => {
        //console.log('fom handle disableemail',errorArr.current.errEmail);
        return errorArr.current.errEmail || errorArr.current.errPassword || errorArr.current.errChkbox || inProcess.current;
    }
    //Change event for Email text box
    var handleEmail = e => {
        e.target.value === '' ? errorArr.current.errEmail = 'The email address is required' : ((!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(e.target.value)) ? errorArr.current.errEmail = 'The email address is invalid' : errorArr.current.errEmail = '');
        setEmail(e.target.value);
        // console.log('in handle email');
    }
    //Change event for Password text box
    var handePassword = e => {
        e.target.value === '' ? errorArr.current.errPassword = 'Password is required.' : errorArr.current.errPassword = '';
        setPassword(e.target.value);
        //   console.log(password,"in handle passwored");

    }

    var handleCheckbox = e => {
        e.target.checked === false ? errorArr.current.errChkbox = 'CheckBox is required' : errorArr.current.errChkbox = '';
        setCheckbox(errorArr.current.errChkbox);
        //  console.log(errorArr.current.errChkbox);
    }
    // console.log("history",history);
    // console.log(history.location.pathname);
    //Click event of login button
    var handleLogin = async () => {

        var databody = "";
        console.log('email', email);
        //console.log('pss',password);
        setErrMessage('');
        //#region Validate the input fields...
        if (email === "" || password === "" || (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email))) {
            if (email === "") {
                errorArr.current.errEmail = 'The email address is required'
            } else if ((!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email))) {
                errorArr.current.errEmail = 'The Email Address is invalid';

            }
            if (password === "") {
                errorArr.current.errPassword = 'Password is required';
            }

            setErrMessage('Please fill all the mandatory fields.');
            return;
        }
        //#endregion
        //After the filelds are validated successfully, disable the button....
        inProcess.current = 'True';
        setProcess("true");

        ////#region Check from URL if the device is fortinet or Juniper and redirect accordingly
        var FortinetorJuniperString = location.pathname;

        if (FortinetorJuniperString.includes("frn")) {

            databody = {
                "username": email,
                "password": password,
                "functionName": "Login",
                "device": "Fortinet",
            }
        } else if (FortinetorJuniperString.includes("synapse")) {

            var expires = Math.round(new Date().getTime() / 1000) + 240;
            databody = {
                "username": email,
                "password": password,
                "functionName": "Login",
                "device": "Juniper",
                "countrycode": localStorage.getItem("Countrycode"),
                "payload": {
                    "ap_mac": values.ap_mac,
                    "wlan_id": values.wlan_id,
                    "client_mac": values.client_mac,
                    "minutes": 1440,
                    "expires": parseInt(expires),
                    "forward": "http://splashpagesbpwifi.s3-website.ap-south-1.amazonaws.com/" + localStorage.getItem("Countrycode") + "/synapse/Success",
                    "email": email,
                    "authorize_only": false
                }

            }
        } else {
            pathPrefix = "/";
        }

        //#endregion

        ////#region After the filelds are validated successfully , make request to  Lamda function through axios
        await axios({
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            crossorigin: true,
            url: "https://g5eyoc0w5h.execute-api.ap-south-1.amazonaws.com/default/splashpage3",
            data: databody
        }).then(res => {
            console.log(res);
            if (res.data.statusCode === 200) {
                inProcess.current = '';
                setProcess("false");
                localStorage.setItem("JuniperURL", res.data.body);
                console.log(res);
                navigate(
                    location.pathname.split('/').slice(0, -1).join('/') + '/Success' + location.search,
                    {
                        state: {
                            username: email,
                            JuniperURL: res.data.body
                        }
                    }
                );
            } else {
                console.log(inProcess.current);
                inProcess.current = '';
                console.log(inProcess.current)
                setErrMessage('UserName or Password is incorrect.');
                setProcess("false");
                console.log(res);
                console.log("errormss", errorMessage);
            }
        }).catch(err => console.log(err))
    }
    //#endregion

    //Return JSX
    return (
        <div>
            <div>
                <form className="form-container">
                    <p className='divTitle'>{datalanguage["Welcome to BP Free WiFi"]}</p>
                    <div className='BPLogo'>
                        <img src={info.bpLogo} alt="BP Logo" className='imgLogo'></img>
                        {/* <img src={require('../BPLogo.png')} className='imgLogo'></img>  */}
                    </div>

                    <div id="popupdiv" className="errorMessage">{errorMessage}</div>
                    <TextComponent value={email} onChange={handleEmail} type="email" id="email"
                                   placeholder={datalanguage["Username"]}
                                   errorMsg={errorArr.current.errEmail}></TextComponent>

                    <TextComponent value={password} onChange={handePassword} type="password" id="password"
                                   placeholder={datalanguage["Password"]} errorMsg={errorArr.current.errPassword}/>
                    <div className='form-group'>
                        <input type='checkbox' onChange={handleCheckbox} value={checkboxValue}
                               id='chkTerms'></input> {datalanguage["By clicking"]} "{datalanguage["Sign Up"]}", {datalanguage["you agree to BP's"]}
                        <a href={pdfurl + tnc}
                           target="_blank" rel="noreferrer">{datalanguage["Terms and Conditions"]} </a>{datalanguage["and"]} <a
                        href={pdfurl + prstmt} target="_blank" rel="noreferrer"> {datalanguage["Privacy Policy"]} </a>
                    </div>
                    <input type="hidden" id="magic"/>
                    <input type="hidden" id="post"/>

                    <button id="submit" disabled={handleDisable()}
                            className="btn btn-success btn-lg btn-block btnCustom" type="button"
                            onClick={handleLogin}>{datalanguage["Login"]}</button>

                    <div className='linkColor'>
                        <Link className='linkColor'
                              to={{
                                  pathname: location.pathname.split('/').slice(0, -1).join('/') + "/Registration",
                                  search: location.search,
                              }}
                        >{datalanguage["Click Here to register"]}</Link>
                        {/* <Link to='/Registration?email={email}' className='linkColor'>{datalanguage["Click Here to register"]}</Link> */}
                        <a href='https://id.bp.com/s/login/ForgotPassword' target="_blank"
                           className='linkColor setlnk' rel="noreferrer">{datalanguage["Forgot password"]}?</a>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Login;