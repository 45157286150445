import './App.css';
import Login from './Components/Login.js';
import Registration from './Components/Registration.js';
import Success from './Components/Success.js';
import Success2 from './Components/Success2.js';
import LandingPage from './Components/LandingPage.js';
import {Route, Routes} from 'react-router-dom';
import VerifyEmail from './Components/verifyemail.js';

function App() {
    return (
        <main id='appId'>
            <Routes>
                {/* **** Salesforce IDP Login route ******** */}
                <Route path='/nl_nl/synapse/LandingPage' element={<LandingPage />} exact/>
                <Route path='/nl_nl/FRN/LandingPage' element={<LandingPage />} exact/>
                <Route path='/de_de/synapse/LandingPage' element={<LandingPage />} exact/>
                <Route path='/de_de/FRN/LandingPage' element={<LandingPage />} exact/>
                <Route path='/en_gb/synapse/LandingPage' element={<LandingPage />} exact/>
                <Route path='/en_gb/FRN/LandingPage' element={<LandingPage />} exact/>
                <Route path='/en_za/synapse/LandingPage' element={<LandingPage />} exact/>
                <Route path='/en_za/FRN/LandingPage' element={<LandingPage />} exact/>
                <Route path='/en_nz/synapse/LandingPage' element={<LandingPage />} exact/>
                <Route path='/en_nz/FRN/LandingPage' element={<LandingPage />} exact/>
                <Route path='/en_us/FRN/LandingPage' element={<LandingPage />} exact/>
                <Route path='/fr_lu/synapse/LandingPage' element={<LandingPage />} exact/>
                <Route path='/fr_lu/FRN/LandingPage' element={<LandingPage />} exact/>
                <Route path='/de_lu/synapse/LandingPage' element={<LandingPage />} exact/>
                <Route path='/de_lu/FRN/LandingPage' element={<LandingPage />} exact/>

                <Route path='/auth/callback' element={<Success2 />} exact/>

                <Route path='/en_gb/FRN/Registration' element={<Registration />} exact/>
                <Route path='/en_za/FRN/Registration' element={<Registration />} exact/>
                <Route path='/en_nz/FRN/Registration' element={<Registration />} exact/>
                <Route path='/en_us/FRN/Registration' element={<Registration />} exact/>
                <Route path='/nl_nl/FRN/Registration' element={<Registration />} exact/>
                <Route path='/fr_lu/FRN/Registration' element={<Registration />} exact/>
                <Route path='/de_lu/FRN/Registration' element={<Registration />} exact/>
                <Route path='/de_de/FRN/Registration' element={<Registration />} exact/>
                <Route path='/en_gb/synapse/Registration' element={<Registration />} exact/>
                <Route path='/en_za/synapse/Registration' element={<Registration />} exact/>
                <Route path='/en_nz/synapse/Registration' element={<Registration />} exact/>
                <Route path='/nl_nl/synapse/Registration' element={<Registration />} exact/>
                <Route path='/fr_lu/synapse/Registration' element={<Registration />} exact/>
                <Route path='/de_lu/synapse/Registration' element={<Registration />} exact/>
                <Route path='/de_de/synapse/Registration' element={<Registration />} exact/>

                <Route path='/FRN/Registration' element={<Registration />} exact/>
                <Route path='/synapse/Registration' element={<Registration />} exact/>

                <Route path='/en_gb/FRN/verifyemail' element={<VerifyEmail />} exact></Route>
                <Route path='/nl_nl/FRN/verifyemail' element={<VerifyEmail />} exact></Route>
                <Route path='/en_us/FRN/verifyemail' element={<VerifyEmail />} exact></Route>
                <Route path='/fr_lu/FRN/verifyemail' element={<VerifyEmail />} exact></Route>
                <Route path='/de_de/FRN/verifyemail' element={<VerifyEmail />} exact></Route>
                <Route path='/en_gb/synapse/verifyemail' element={<VerifyEmail />} exact></Route>
                <Route path='/nl_nl/synapse/verifyemail' element={<VerifyEmail />} exact></Route>
                <Route path='/fr_lu/synapse/verifyemail' element={<VerifyEmail />} exact></Route>
                <Route path='/de_de/synapse/verifyemail' element={<VerifyEmail />} exact></Route>

                <Route path='/synapse/verifyemail' element={<VerifyEmail />} exact></Route>
                <Route path='/FRN/verifyemail' element={<VerifyEmail />} exact></Route>

                {/* **** Old Login route ******** */}
                <Route path='/Login' element={<Login />} exact/>
                <Route path='/FRN/Login' element={<Login />} exact/>
                <Route path='/synapse/Login' element={<Login />} exact/>

                <Route path='/en_gb/FRN/Login' element={<Login />} exact/>
                <Route path='/nl_nl/FRN/Login' element={<Login />} exact/>
                <Route path='/en_us/FRN/Login' element={<Login />} exact/>
                <Route path='/fr_lu/FRN/Login' element={<Login />} exact/>
                <Route path='/de_de/FRN/Login' element={<Login />} exact/>

                <Route path='/en_gb/synapse/Login' element={<Login />} exact/>
                <Route path='/nl_nl/synapse/Login' element={<Login />} exact/>
                <Route path='/fr_lu/synapse/Login' element={<Login />} exact/>
                <Route path='/de_de/synapse/Login' element={<Login />} exact/>

                <Route path='/en_gb/FRN/Success' element={<Success />} exact/>
                <Route path='/en_us/FRN/Success' element={<Success />} exact/>
                <Route path='/nl_nl/FRN/Success' element={<Success />} exact/>
                <Route path='/fr_lu/FRN/Success' element={<Success />} exact/>
                <Route path='/de_de/FRN/Success' element={<Success />} exact/>

                <Route path='/en_gb/synapse/Success' element={<Success />} exact/>
                <Route path='/nl_nl/synapse/Success' element={<Success />} exact/>
                <Route path='/fr_lu/synapse/Success' element={<Success />} exact/>
                <Route path='/de_de/synapse/Success' element={<Success />} exact/>

                <Route path='/synapse/Success' element={<Success />} exact/>
                <Route path='/FRN/Success' element={<Success />} exact/>
            </Routes>
        </main>
    );
}

export default App;
